import { setupFCM } from 'src/utils/fcm';
export const handleNotificationPermissions = async () => {
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications.');
      return;
    }
  
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      setupFCM();
    } else if (permission === 'denied') {
      if (localStorage.getItem('fcmToken')) {
        localStorage.removeItem('fcmToken');
      }
    }
  };
  