<template>
  <a-spin v-if="isScheduling"></a-spin>
  <div v-else>
    <a-radio-group
      v-model:value="selectedScheduleType"
      size="small"
      style="width: 400px"
      button-style="solid"
      @change="onTypeChange"
    >
      <a-radio-button
        class="mr-3"
        v-for="type in scheduleTypes"
        :key="type"
        :value="type"
        >{{ type }}</a-radio-button
      >
    </a-radio-group>
    <a-time-range-picker
      id="dm-time-range-input"
      :disabled="!selectedScheduleType"
      format="HH:mm"
      class="w-100 mt-3"
      style="width: 200px"
      @change="onTimeRangeChange"
    />
  </div>
</template>

<script>
import { useDeviceSettings } from '../RecordData/composables/useDeviceSettings';
import daysOptions from 'src/config/days-config.js';
import dateHelper from '../../../shared/Helpers/dateHelper';
import { useToast } from 'vue-toastification';

export default {
  name: 'AssignedDevice',
  props: ['showScheduleTaskModal', 'record', 'deviceDetails'],
  emits: ['toggleScheduleTaskModal'],
  setup() {
    const toast = useToast();
    const { isScheduling, updateDeviceSettings } = useDeviceSettings(toast);

    async function handleScheduleForSelectedType() {
      isScheduling.value = true;
      await this.handleScheduleTask();
      // this.$emit('toggleScheduleTaskModal');
      isScheduling.value = false;
      return;
    }

    async function handleScheduleTask() {
      const { id } = this.record;
      const payload = this.getSchedulePayload();
      console.log(id, payload);
      const res = await this.updateDeviceSettings(id, payload);
      if (res === 'error') {
        this.toast.error('Error occured while Scheduling!', { timeout: 2000 });
        return;
      }
      this.toast.success('Your device has been scheduled successfully', {
        timeout: 2000
      });
    }
    return {
      toast,
      isScheduling,
      handleScheduleTask,
      handleScheduleForSelectedType,
      // showSchedule,
      updateDeviceSettings,
      daysOptions
    };
  },
  data() {
    return {
      scheduleTypes: ['Video Upload', 'Analytics Upload'],
      selectedScheduleType: null,
      startEndTime: [],
      selectedDays: [],
      startTime: null,
      endTime: null
    };
  },
  created() {
    // this.selectedDays = JSON.parse(this.record?.day_of_week_for_recording);
  },

  methods: {
    showModal() {
      // this.showScheduleModalAnalytics(this.record);
      return this.showScheduleTaskModal;
    },
    closeModal() {
      this.$emit('toggleScheduleTaskModal');
      return;
      // this.showScheduleTaskModal = false;
    },

    onTimeRangeChange(value) {
      this.startEndTime = value;
    },
    onTypeChange(event) {
      const { value } = event.target;
      this.selectedScheduleType = value;
    },
    getStartEndTime() {
      const [start, end] = this.startEndTime;
      if (!start.$d && !end.$d) {
        this.toast.info('Schedule time is not properly selected!');
        //   isScheduling.value = false;
        return;
      }
      const startDate = dateHelper.getFormattedDate(start.$d);
      const endDate = dateHelper.getFormattedDate(end.$d);
      const [startTime] = new Date(start.$d).toTimeString().split(' ');
      const [endTime] = new Date(end.$d).toTimeString().split(' ');
      return [startDate + 'T' + startTime, endDate + 'T' + endTime];
    },
    onDaysSelect(days) {
      console.log('days', days);
    },
    getSchedulePayload() {
      const [start, end] = this.getStartEndTime(this.startEndTime);
      const taskId = this.record.Task;
      const scheduleType = this.selectedScheduleType;

      const schedule = {
        Task: taskId,
        is_shift_managed: false
      };

      if (scheduleType === 'Video Upload') {
        schedule.start_recording_time = start;
        schedule.end_recording_time = end;
      } else if (scheduleType === 'Analytics Upload') {
        schedule.analytics_start_time = start;
        schedule.analytics_end_time = end;
      }
      // else if (scheduleType === 'Trace') {
      //   schedule.trace_start_time = start;
      //   schedule.trace_end_time = end;
      //   schedule.is_shift_managed = false;
      // }
      // else if (scheduleType === 'Inference') {
      //   schedule.shift_start_time = start;
      //   schedule.shift_end_time = end;
      //   schedule.recordInference = this.deviceDetails.recordInference;
      // }

      return schedule;
    }
  }
};
</script>
