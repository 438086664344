<template>
  <!-- Stream Video Modal -->
  <a-modal
    id="devices-schedule-task-model"
    v-model:visible="showScheduleTaskModal"
    title="Schedule"
    destroy-on-close
    :mask-closable="false"
    :ok-button-props="{ id: 'dm-schedule-task-ok-btn' }"
    ok-text="Schedule"
    :cancel-button-props="{
      id: 'dm-schedule-task-cancel-btn',
      style: { marginLeft: 'auto' }
    }"
    @ok="handleScheduleForSelectedType"
    @cancel="closeScheduleTaskModal"
  >
    <ScheduleTask
      ref="ScheduleTask"
      :showScheduleTaskModal="showScheduleTaskModal"
      :record="item"
      :deviceDetails="deviceDetails"
      @toggleScheduleTaskModal="toggleScheduleTaskModal"
    />
  </a-modal>
  <a-modal
    v-model:visible="showStreamModal"
    :footer="null"
    width="1000px"
    centered
    destroy-on-close
    :title="item.title"
    :get-popup-container="() => document.getElementById('stream-modal-body')"
    @cancel="handleStreamModalClose"
  >
    <div id="stream-modal-body" class="w-100">
      <VideoStream
        v-if="rtcServerThreadStatus === true && streamSignal === true"
        :serialNumber="item.Serial_number"
        :deviceSetting="item.id"
        @closeModal="showStreamModal = false"
      />

      <screen-shot
        v-else
        :snap-shot="snapShot"
        :device-setting-url="deviceSettingUrl"
        :serial-number="serialNumber"
        :is-default-image="isDefaultImage"
        :task_list="task_list"
        :device-cropping-params="deviceSetting.cropping_params"
        :device-id="deviceSetting.id"
        :device-camera-mode="deviceSetting.mode"
        :task-cropping-params="currentTask.cropping_params"
        :current-selected-task="currentTask.id"
        @setCameraMode="setCurrentCameraMode"
        @changeCroppingSignal="val => (croppingSignal = val)"
        @setControlImageTime="setControlImageTime"
      />
    </div>
  </a-modal>

  <!-- <a-modal
    v-model:visible="showTowerLightModal"
    :footer="null"
    width="1000px"
    centered
    destroy-on-close
    title="Tower Light Configuration"
    @cancel="hidePateliteTower"
  >
  <signal-tower :device="deviceSetting" @hidePateliteTower="hidePateliteTower" />
  </a-modal> -->

  <!-- starred device -->
  <td>
    <a-rate
      style="margin-top: -0.3em; font-size: 2em"
      :value="item.starred ? 1 : 0"
      :count="1"
      class="ml-2"
      @change="handleChangeStarred"
    />
  </td>

  <!--  "Serial_number",-->
  <td class="black-color list-cell d-flex flex-column align-items-center">
    <a-popover arrow hover placement="top">
      <div v-if="!isEdit" class="display-name">
        {{
          item.title.length > 20 ? item.title.slice(0, 20) + '...' : item.title
        }}
      </div>
      <template #content>
        <div v-for="row in deviceHoverInfo" :key="row.key">
          <span v-if="row.key === 'Organization'">
            {{ row.title }} :
            <a-tag color="processing">
              {{ item[row.key].Org_name || organization }}
            </a-tag>
          </span>
          <span v-else-if="row.key === 'version_no'">
            {{ row.title }} :
            <a-tag color="processing">{{ version_no || '-' }}</a-tag>
          </span>
          <span v-else>
            {{ row.title }} :
            <a-tag color="processing">{{ item[row.key] || '-' }}</a-tag>
          </span>
        </div>
      </template>
    </a-popover>

    <div v-if="isEdit" style="display: flex">
      <a-input
        v-model:value="deviceDetails.display_name"
        placeholder="Device Name"
        size="small"
        style="width: 120px"
      />
      <a-button class="ml-1" size="small" @click="handleSaveDeviceName"
        >Update</a-button
      >
      <a-button class="ml-1" size="small" @click="isEdit = false"
        >Cancel</a-button
      >
    </div>
    <div>
      <a-tooltip title="Show Live stream">
        <i
          class="bi bi-cast image-icon"
          :class="{ disabled: !isActive || isEdit }"
          @click="showStream"
        />
      </a-tooltip>
      <!-- <span class="mx-1" />
      <a-tooltip title="Show Tower Light Configuration">
        <i
          class="bi bi-broadcast-pin image-icon"
          :class="{ disabled: isInactive || isEdit }"
          @click="handleSignalTowerModal"
        ></i>
      </a-tooltip> -->
      <span class="mx-1" />
      <a-popconfirm ok-text="Yes" cancel-text="No" @confirm="restartDevice">
        <template #icon>
          <question-circle-outlined style="color: red" />
        </template>
        <template #title>
          Are you sure? You want to restart
          <strong>{{ item.title }}</strong
          >.
        </template>
        <a-tooltip title="Restart Device">
          <i
            class="bi bi-arrow-counterclockwise image-icon"
            :class="{ disabled: !isActive || isEdit }"
          />
        </a-tooltip>
      </a-popconfirm>
      <span class="mx-1" />
      <a-tooltip title="Edit device name">
        <i
          class="bi bi-pencil-square image-icon"
          :class="{ disabled: !isActive || isEdit }"
          @click="handleSaveDeviceName"
        />
        <!-- <edit-outlined :class="{ disabled: !isActive || isEdit }" @click="handleSaveDeviceName"/> -->
      </a-tooltip>
    </div>

    <small v-if="deviceActivity" class="mt-2">
      {{ deviceActivity }}
    </small>
    <a-tooltip :title="getDeviceStatus || ''">
      <a-tag :color="deviceStatusColor" class="mt-2 download-upload-details">
        {{ getDeviceStatus }}
      </a-tag>
    </a-tooltip>

    <!-- Downloading/Uploading Details -->
    <a-tag v-if="dataTransferDetails" class="mt-1 text-center">
      <template #icon>
        <span v-if="dataTransferDetails?.progress > 0">
          {{ dataTransferDetails.progress + '%' }}
        </span>
      </template>
      &nbsp;{{ dataTransferDetails.transferType }}
    </a-tag>

    <a-tooltip :title="dataTransferDetails.name">
      <small v-if="dataTransferDetails" class="download-upload-details">
        {{ dataTransferDetails.name }}
      </small>
    </a-tooltip>
  </td>

  <!--    manage device-->
  <td>
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}101`"
        v-model="deviceDetails.manage"
        type="checkbox"
        class="custom-control-input"
        :disabled="!isActive"
      />
      <label
        class="custom-control-label"
        :class="{
          'text-secondary': !isActive,
          'text-dark': isActive
        }"
        :for="`${item.id}101`"
      >
        {{ tableRowColumns[3] }}
      </label>
    </div>
  </td>

  <!-- record controls -->
  <td class="black-color list-cell">
    <!--    record shift-->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}3${index}`"
        v-model="deviceDetails.record_shift"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}3${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][3] }}
      </label>
    </div>

    <!--    record inference-->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}1${index}`"
        v-model="deviceDetails.recordInference"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}1${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][1] }}
      </label>
    </div>
    <div>
      <a-button
        block
        class="mt-1"
        size="middle"
        type="primary"
        :disabled="!isActive"
        @click="toggleScheduleTaskModal"
      >
        Scheduler
      </a-button>
    </div>
  </td>

  <!-- "Device controls" -->
  <td class="black-color list-cell">
    <!--  "Projector"-->
    <div class="custom-control custom-switch">
      <input
        v-model="deviceDetails.projector_enable"
        type="checkbox"
        class="custom-control-input"
        :id="`${item.id}8${index}`"
        :disabled="
          isControlsDisabled || deviceSetting.length === 0 || isInactive
        "
      />
      <label
        class="custom-control-label"
        :for="`${item.id}8${index}`"
        :class="{
          'text-secondary': deviceSetting.length === 0 || isInactive
        }"
        >{{ tableRowColumns[4][8] }}</label
      >
    </div>

    <!--    blurFace-->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}0${index}`"
        v-model="deviceDetails.blurFace"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :class="{ 'text-secondary': !isActive }"
        :for="`${item.id}0${index}`"
      >
        {{ tableRowColumns[4][0] }}
      </label>
    </div>

    <!-- enable translation -->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}6${index}`"
        v-model="deviceDetails.is_translation_enabled"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}6${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][6] }}
      </label>
    </div>

    <!-- Box Visualization -->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}9${index}`"
        v-model="deviceDetails.box_visualization"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}9${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][9] }}
      </label>
    </div>
  </td>

  <!-- camera controls -->
  <td class="black-color list-cell">
    <!-- Depth Camera -->
    <div class="custom-control custom-switch">
      <input
        v-model="deviceDetails.depth_cam_enabled"
        type="checkbox"
        class="custom-control-input"
        :id="`${item.id}7${index}`"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :class="{ 'text-secondary': !isActive }"
        :for="`${item.id}7${index}`"
        >{{ tableRowColumns[4][7] }}</label
      >
    </div>

    <a-tooltip>
      <!--    multi view-->
      <div class="custom-control custom-switch">
        <input
          v-model="deviceDetails.multi_view"
          :id="`${item.id}4${index}`"
          type="checkbox"
          class="custom-control-input"
          :disabled="
            isControlsDisabled ||
              deviceDetails.recordInference ||
              deviceDetails.record_shift
          "
        />
        <label
          class="custom-control-label"
          :for="`${item.id}4${index}`"
          :class="{ 'text-secondary': !isActive }"
        >
          {{ tableRowColumns[4][4] }}
        </label>
      </div>
      <!--    multi cam-->
      <div class="custom-control custom-switch">
        <input
          :id="`${item.id}5${index}`"
          v-model="deviceDetails.remote_cam_enabled"
          type="checkbox"
          class="custom-control-input"
          :disabled="
            isControlsDisabled ||
              deviceDetails.recordInference ||
              deviceDetails.record_shift
          "
        />
        <label
          class="custom-control-label"
          :for="`${item.id}5${index}`"
          :class="{ 'text-secondary': !isActive }"
          >{{ tableRowColumns[4][5] }}</label
        >
      </div>

      <template
        #title
        v-if="deviceDetails.record_shift || deviceDetails.recordInference"
      >
        Disable all recordings first
      </template>
    </a-tooltip>
  </td>

  <!--  "Current_task_running",-->
  <td class="black-color list-cell pl-4" style="width: 200px">
    <div class="black-color list-cell my-2">
      <label>
        Current Task:

        <a-tag
          v-if="deviceDetails.manage"
          :color="isActive && item.Task ? 'processing' : 'default'"
        >
          {{
            isActive && taskIdToTaskNameMap[item.Task]
              ? taskIdToTaskNameMap[item.Task]
              : 'None'
          }}
        </a-tag>
      </label>
    </div>
    <div class="black-color list-cell my-2">
      <a-select
        v-model:value="deviceDetails.Task"
        placeholder="Select task"
        style="width: 240px"
        :loading="isLoading || isTaskUpdating"
        show-search
        :options="taskOptions"
        :filter-option="filterOption"
        :disabled="isControlsDisabled"
      />
    </div>
  </td>

  <!--  "start end time",-->
  <!-- <td>
    <div class="black-color list-cell my-2">
      <a-time-picker
        v-model:value="deviceDetails.shift_start_time"
        value-format="HH:mm:ss"
        :disabled="isControlsDisabled"
        style="width: 120px"
      />
    </div>

    <div class="black-color list-cell my-2">
      <a-time-picker
        v-model:value="deviceDetails.shift_end_time"
        value-format="HH:mm:ss"
        :disabled="isControlsDisabled"
        style="width: 120px"
      />
    </div>
  </td> -->

  <!-- <td>
    <div :disabled="isControlsDisabled">
      <schedule-task-column
        :showScheduleTaskModal="showScheduleTaskModal"
        :record="deviceDetails"
        :isControlsDisabled="isControlsDisabled"
        @toggleScheduleTaskModal="toggleScheduleTaskModal"
        @updateDaysOfSchedule="updateDaysOfSchedule"
        @updateTimeOfSchedule="updateTimeOfSchedule"
      />
    </div>
  </td> -->

  <!--  "inferenceControls",-->
  <td>
    <a-space class="w-100" direction="vertical" align="center">
      <a-button
        type="primary"
        ghost
        :disabled="isControlsDisabled || deviceDetails.isInferenceRunning"
        @click="startInference"
      >
        Start Task
      </a-button>
      <a-button
        type="primary"
        ghost
        :disabled="isControlsDisabled || !deviceDetails.isInferenceRunning"
        @click="stopInference"
      >
        Stop Task
      </a-button>
    </a-space>
  </td>

  <!--  "updateDevice"-->
  <td>
    <a-space class="w-100" direction="vertical">
      <a-button
        block
        type="primary"
        :loading="isApplyingChanges"
        :disabled="!isActive"
        @click="updateDevice"
      >
        Apply To Device
      </a-button>
      <a-button
        block
        type="primary"
        :disabled="!isActive"
        @click="switchCamera(item)"
      >
        Flip Camera
      </a-button>
      <a-button block type="primary" @click="fetchAnalytics(item)">
        Pull Analytics
      </a-button>

      <!-- <a-button block type="primary" @click="handleSaveDeviceName">
        {{ isEdit ? 'Update' : 'Edit' }} Device Name
      </a-button>
      <a-button block v-if="isEdit" @click="isEdit = false"> Cancel </a-button> -->
    </a-space>
  </td>
</template>

<script>
import Popper from 'vue3-popper';
import httpClient from 'src/service/httpClient';
import DeviceService from 'src/services/device.js';
import dateHelper from '../../../shared/Helpers/dateHelper';
import WebRtc from './WebRtc';
import VideoStream from './VideoStream.vue';
import ScreenShot from './ScreenShot.vue';
import {
  QuestionCircleOutlined,
  EditOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  EllipsisOutlined
} from '@ant-design/icons-vue';
import { getSortedTask } from 'src/utils/task';
import spaceMixin from 'src/mixins/handleSpace';
import { mapGetters } from 'vuex';
import ScheduleTask from './ScheduleTask.vue';
import daysOptions from 'src/config/days-config.js';
import ScheduleTaskColumn from './ScheduleTaskColumn.vue';
import fcm from 'src/services/fcm';

export default {
  name: 'AssignedDevice',
  components: {
    WebRtc,
    VideoStream,
    Popper,
    ScreenShot,
    ScheduleTask,
    ScheduleTaskColumn,
    QuestionCircleOutlined,
    EditOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    EllipsisOutlined,
    daysOptions
  },
  mixins: [spaceMixin],
  inject: ['toast'],
  props: [
    'item',
    'index',
    'tableRowColumns',
    'deviceHoverInfo',
    'isLoading',
    'task_list',
    'trainedTasks',
    'activeDevices',
    'allDevices',
    'taskIdToTaskNameMap'
  ],
  emits: [
    'unAssignDeviceFromDept',
    'updateDeviceList',
    'isModalOpen',
    'closeModal'
  ],

  data() {
    return {
      deviceDetails: {},
      isEdit: false,
      deviceSetting: {},
      oldDeviceSetting: {},
      currentTask: {},
      isTaskUpdating: false,
      pollingTime: 6000,
      polling: null,
      start_time: '00:00:00',
      end_time: '23:59:59',
      tempDeviceSetting: {},
      isRequestResolved: true,
      date: new Date(),
      currentTime: null,
      currentFormattedDate: null,
      analytics_start_time: '00:00:00',
      analytics_end_time: '23:59:59',
      showMoreOptions: false,
      // ================ device status ================
      serialNumber: '',
      deviceSettingUrl: '',
      image: '',
      isDeviceStatusPolled: false,
      deviceStatusPolling: null,
      deviceStatus: ['Inactive'],
      isDownloading: false,
      isUploading: false,
      progress: 0,
      deviceCroppingParams: {},
      controlTime: '',
      showTowerLightModal: false,
      // ========= snapshot
      showStreamModal: false,
      streamSignalPolling: null,
      streamSignal: false,
      croppingSignal: false,
      currentCameraMode: '',
      isDefaultImage: false,
      snapShot: null,
      downloadUploadDetails: '',
      openRestartDeviceDialog: true,
      openUnassignDialog: true,
      deviceToUnassign: '',
      isStreamLoading: false,
      rtcServerThreadStatus: false,
      projector: false,
      isUnmounted: false,
      isApplyingChanges: false,
      version_no: '',
      isModelToastShown: false,
      oldDisplayName: '',
      showScheduleTaskModal: false,
      currentRecord: {}
    };
  },

  computed: {
    ...mapGetters(['organization']),
    getDeviceStatus() {
      const {
        is_shift_managed,
        record_shift,
        isInferenceRunning,
        inference,
        recording,
        task,
        active
      } = this.item;
      if (is_shift_managed) {
        if (inference || recording) return task;
      }
      if (record_shift || isInferenceRunning) return task;
      return active ? 'Active' : 'Inactive';
    },

    deviceStatusColor() {
      return {
        Active: 'processing',
        Inactive: 'default'
      }[this.getDeviceStatus];
    },

    deviceActivity() {
      const {
        is_shift_managed,
        record_shift,
        isInferenceRunning,
        inference,
        recording
      } = this.item;
      if (is_shift_managed) {
        if (inference) return 'Inferencing for ';
        if (recording) return 'Recording for ';
      }
      if (isInferenceRunning) return 'Inferencing for ';
      if (record_shift) return 'Recording for ';
      return '';
    },

    dataTransferDetails() {
      const { downloading, uploading } = this.item;
      if (downloading)
        return this.getDataTransferTypeDetails(downloading, 'Downloading');

      if (uploading)
        return this.getDataTransferTypeDetails(uploading, 'Uploading');

      return false;
    },

    taskOptions() {
      return getSortedTask(this.task_list);
    },

    isActive() {
      return !!this.item.active;
    },

    isControlsDisabled() {
      return !this.isActive || !this.deviceDetails.manage;
    }
  },

  watch: {
    item: {
      handler: function(newVal) {
        this.setDeviceStatusState(newVal);
        this.getDeviceSettings();
      },
      deep: true
    }

    // activeDevices() {
    //   if (this.isDeviceStatusPolled) return;
    //   this.getDeviceSettings();
    // }
  },
  created() {
    window.addEventListener('beforeunload', this.resetPolling);
    this.deviceDetails = {
      ...this.item,
      shift_start_time: this.item.shift_start_time
        ?.split('T')
        .pop()
        .replace('Z', ''),
      shift_end_time: this.item.shift_end_time
        ?.split('T')
        .pop()
        .replace('Z', '')
    };
  },

  beforeUnmount() {
    this.isUnmounted = true;
    this.resetPolling();
  },

  methods: {
    async fetchAnalytics(record) {
      const payload = {
        data: { telemetry: true },
        type: 'set_telemetry',
        message_time: new Date().toISOString(),
        platform: 'portal',
        organization: localStorage.getItem('organization'),
        device_id: record.Serial_number
      };
      const [error, data] = await fcm.sendMessageToRabbitMQ(payload, false);
      if (error) throw 'Error while sending set telemetry request';
    },
    closeScheduleTaskModal() {
      this.showScheduleTaskModal = false;
      this.$emit('closeModal', false);
    },
    toggleScheduleTaskModal(val) {
      this.showScheduleTaskModal = !this.showScheduleTaskModal;
      this.$emit('isModalOpen', true);
    },
    getDataTransferTypeDetails(typeObj, type) {
      return {
        name: typeObj.name,
        progress: typeObj.progress,
        transferType: type
      };
    },
    restartDeviceDialog() {
      this.openRestartDeviceDialog = !this.openRestartDeviceDialog;
    },

    async restartDevice() {
      const date = new Date();
      const date_today = dateHelper.getFormattedDate(date) + 'T';
      const time_now =
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        'Z';
      const time_to_restart = date_today + time_now;
      await this.SendDeviceUpdate({ restart_time: time_to_restart });
    },

    getDisplayName() {
      return this.item[this.tableRowColumns[7]]
        ? this.item[this.tableRowColumns[7]]
        : this.item[this.tableRowColumns[0]]
        ? this.item[this.tableRowColumns[0]]
        : '-';
    },

    getDeviceSettingUrl() {
      return (
        'device/' +
        this.organization +
        '/device_settings/' +
        this.item.Serial_number +
        '/'
      );
    },

    getDeviveSettingUrl(setting) {
      if (!setting) return '';
      return `device/device_status/?Serial_number=${setting.Device.Serial_number}`;
    },

    async getDeviceSettings() {
      if (this.isUnmounted) return;
      // if (!this.activeDevices.includes(this.item.Serial_number)) {
      //   if (this.showStreamModal){
      //     this.toast.info('Your device has been deactivated!');
      //     this.getDeviceStatus();
      //   }
      //   this.showStreamModal = false;
      //   return;
      // }
      // if (!this.activeDevices.includes(this.item.Serial_number)) return;
      this.isDeviceStatusPolled = true;
      let deviceSettingsURL = this.getDeviceSettingUrl();
      const list = await httpClient.get(deviceSettingsURL, false);
      const [deviceSetting] = list;
      this.deviceSettingUrl = this.getDeviveSettingUrl(deviceSetting);
      // await this.getDeviceStatus();

      if (deviceSetting) {
        if (
          JSON.stringify(this.tempDeviceSetting) !==
          JSON.stringify(deviceSetting)
        ) {
          this.deviceSetting = JSON.parse(JSON.stringify(deviceSetting));

          this.currentCameraMode = this.deviceSetting?.mode;
          if (this.deviceSetting.shift_start_time) {
            this.start_time = this.deviceSetting.shift_start_time
              .split('T')[1]
              .slice(0, -1);
          }
          if (this.deviceSetting.shift_end_time) {
            this.end_time = this.deviceSetting.shift_end_time
              .split('T')[1]
              .slice(0, -1);
          }
          if (this.deviceSetting.analytics_start_time) {
            this.analytics_start_time = this.deviceSetting.analytics_start_time
              .split('T')[1]
              .slice(0, -1);
          }
          if (this.deviceSetting.analytics_end_time) {
            this.analytics_end_time = this.deviceSetting.analytics_end_time
              .split('T')[1]
              .slice(0, -1);
          }
          this.tempDeviceSetting = { ...deviceSetting };

          if (this.deviceSetting.Task) {
            this.currentTask = this.deviceSetting.Task;
          }
          this.saveCopyOfDeviceSetting();
        }
      }
    },

    async getImage() {
      let deviceSettingsURL = this.getDeviceSettingUrl();

      const list = await httpClient.get(deviceSettingsURL, false);
      const [deviceSetting] = list;
      this.deviceSettingUrl = this.getDeviveSettingUrl(deviceSetting);
      await this.getDeviceStatus();
      return this.snapShot;
    },

    saveCopyOfDeviceSetting() {
      this.currentFormattedDate = dateHelper.getFormattedDate(this.date);
      this.oldDeviceSetting = {
        blurFace: this.deviceSetting.blurFace,
        isInferenceRunning: this.deviceSetting.isInferenceRunning,
        manage: this.deviceSetting.manage,
        recordInference: this.deviceSetting.recordInference,
        is_shift_managed: this.deviceSetting.is_shift_managed,
        record_shift: this.deviceSetting.record_shift,
        shift_start_time: this.currentFormattedDate + 'T' + this.start_time,
        shift_end_time: this.currentFormattedDate + 'T' + this.end_time,
        control_image_time:
          this.currentFormattedDate + 'T' + (this.controlTime || '00:00:00Z'),
        mode: this.currentCameraMode,
        multi_view: this.deviceSetting.multi_view,
        is_translation_enabled: this.deviceSetting.is_translation_enabled,
        remote_cam_enabled: this.deviceSetting.remote_cam_enabled,
        analytics_start_time:
          this.currentFormattedDate + 'T' + this.analytics_start_time,
        analytics_end_time:
          this.currentFormattedDate + 'T' + this.analytics_end_time,
        projector_enable: this.deviceSetting.projector_enable,
        depth_cam_enabled: this.deviceSetting.depth_cam_enabled
      };
    },

    setControlImageTime() {
      const date = new Date();
      this.controlTime =
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        'Z';
      this.updateDevice();
    },

    // async getDeviceStatus() {
    //   if (this.isUnmounted || !this.deviceSettingUrl) return;

    //   const res = await httpClient.getData(this.deviceSettingUrl, false);
    //   if (res?.device_status?.image) {
    //     this.image = res.device_status.image;
    //   }
    //   this.isDeviceStatusPolled = false;
    //   const { device_status } = res;
    //   if (!device_status) {
    //     clearTimeout(this.deviceStatusPolling);
    //     return;
    //   }
    //   this.setDeviceStatusState(device_status);
    // },

    async sendSignalToStream() {
      const data = {
        [`${this.serialNumber}stream`]: this.streamSignal,
        [`${this.serialNumber}is_cropping`]: this.croppingSignal,
        Serial_number: this.serialNumber
      };
      await httpClient.post(this.deviceSettingUrl, data, false, false, false);
    },

    setDeviceStatusState(device_status) {
      const {
        downloading,
        active,
        uploading,
        inference,
        recording,
        task,
        image,
        answer,
        version_no,
        rtc_thread_status,
        is_projector_attached
      } = device_status;
      if (inference) {
        this.deviceStatus = ['Inferencing for ', task];
      } else if (recording) {
        this.deviceStatus = ['Recording for ', task];
      } else if (active) {
        this.deviceStatus = ['Active'];
      } else {
        this.deviceStatus = ['Inactive'];
      }
      if (version_no) {
        this.version_no = version_no;
      }
      this.isDownloading = !!downloading;
      this.isUploading = !!uploading;
      this.snapShot = image ? 'data:image/jpg;base64, ' + image : false;
      this.isDefaultImage = answer;
      let progress, name;
      if (downloading) {
        progress = downloading.progress;
        name = downloading.name;
        if (name.endsWith('.zip') && !this.isModelToastShown) {
          this.isModelToastShown = true;
          this.toast.info(
            `We are downloading the model on your device. This will take several minutes.`
          );
        }
      }
      if (uploading) {
        progress = uploading.progress;
        name = uploading.name;
      }
      this.downloadUploadDetails = name;
      this.progress = progress ? progress : 0;

      if (rtc_thread_status) {
        this.rtcServerThreadStatus = rtc_thread_status;
      }

      this.projector = is_projector_attached;
    },

    setCurrentCameraMode(value) {
      this.deviceDetails.mode = value;
      this.updateDevice();
    },

    isTrainedTask(taskId = this.item.Task) {
      return !this.trainedTasks.map(({ task }) => task.id).includes(taskId);
    },

    startInference() {
      if (this.isTrainedTask()) {
        this.toast.info('Cannot run inference on Untrained task!');
        return;
      }
      this.deviceDetails.isInferenceRunning = true;
    },

    stopInference() {
      this.deviceDetails.isInferenceRunning = false;
    },

    isTaskChanged() {
      return this.item.Task !== this.deviceDetails.Task;
    },

    validateRecordShift() {
      const {
        record_shift,
        isInferenceRunning,
        is_shift_managed
      } = this.deviceDetails;
      if (
        record_shift &&
        (isInferenceRunning || is_shift_managed || this.isTaskChanged())
      ) {
        this.renderErrorToast(
          'You cannot start inference, manage shift or change task when recording shift.'
        );
        return false;
      }
      return true;
    },

    validateRecordInference() {
      const {
        is_shift_managed,
        recordInferenceFlipped,
        isInferenceRunning,
        isShiftTime
      } = this.getDeviceStates();

      if (is_shift_managed && recordInferenceFlipped && isShiftTime)
        return this.renderErrorToast(
          'Recording cannot be toggled while Manage Shift is enabled. Please disable Manage Shift first.'
        );

      if (
        recordInferenceFlipped &&
        isInferenceRunning &&
        !this.deviceDetails.is_shift_managed
      )
        return this.renderErrorToast(
          'Recording cannot be toggled while Inference is running. Please stop Inference first.'
        );

      return true;
    },

    getDeviceStates() {
      return {
        is_shift_managed: this.isDeviceOn('is_shift_managed'),
        recordInferenceFlipped: this.isControlFlipped('recordInference'),
        isInferenceRunning: this.isDeviceOn('isInferenceRunning'),
        isShiftTime: this.isShiftTime()
      };
    },

    renderErrorToast(message) {
      const timeout = { timeout: 2000 };
      this.toast.info(message, timeout);
      return false;
    },

    isDeviceOn(deviceProp) {
      const oldSettings = this.item;
      const newSettings = this.deviceDetails;

      const oldS = oldSettings[deviceProp];
      const newS = newSettings[deviceProp];
      return oldS === true && newS === true;
    },

    isControlFlipped(deviceProp) {
      const oldSettings = this.item;
      const newSettings = this.deviceDetails;

      const oldS = oldSettings[deviceProp];
      const newS = newSettings[deviceProp];
      return !(oldS === newS);
    },

    isShiftTime() {
      if (!prevEndShiftTime || !prevStartShiftTime) {
        return false;
      }
      let prevStartShiftTime = new Date(
        this.item.shift_start_time.slice(0, -1)
      ).getTime();
      let prevEndShiftTime = new Date(
        this.item.shift_end_time.slice(0, -1)
      ).getTime();
      let currentTime = new Date().getTime();
      return prevStartShiftTime < currentTime && currentTime < prevEndShiftTime;
    },

    getStartEndTIme() {
      const { currentFormattedDate } = this;
      const { shift_start_time, shift_end_time } = this.deviceDetails;
      return {
        shift_start_time: shift_start_time
          ? currentFormattedDate + 'T' + shift_start_time
          : null,
        shift_end_time: shift_end_time
          ? currentFormattedDate + 'T' + shift_end_time
          : null
      };
    },

    setupUpdateDeviceSetting() {
      this.date = new Date();
      this.currentFormattedDate = dateHelper.getFormattedDate(this.date);
      if (!this.controlTime) this.controlTime = '00:00:00Z';
      let data = {
        blurFace: this.deviceDetails.blurFace,
        depth_cam_enabled: this.deviceDetails.depth_cam_enabled,
        isInferenceRunning: this.deviceDetails.isInferenceRunning,
        manage: this.deviceDetails.manage,
        recordInference: this.deviceDetails.recordInference,
        is_shift_managed: this.deviceDetails.is_shift_managed,
        record_shift: this.deviceDetails.record_shift,
        // shift_start_time: this.currentFormattedDate + 'T' + this.start_time,
        // shift_end_time: this.currentFormattedDate + 'T' + this.end_time,
        control_image_time: this.currentFormattedDate + 'T' + this.controlTime,
        mode: this.currentCameraMode,
        multi_view: this.deviceDetails.multi_view,
        is_translation_enabled: this.deviceDetails.is_translation_enabled,
        analytics_start_time:
          this.currentFormattedDate + 'T' + this.analytics_start_time,
        analytics_end_time:
          this.currentFormattedDate + 'T' + this.analytics_end_time,
        projector_enable: this.deviceDetails.projector_enable,
        remote_cam_enabled: this.deviceDetails.remote_cam_enabled,
        day_of_week_for_recording: this.deviceDetails.day_of_week_for_recording,
        box_visualization: this.deviceDetails.box_visualization
      };

      // data = {
      //   ...data,
      //   ...this.getStartEndTIme(),
      //   mode: this.currentCameraMode,
      //   multi_view: this.deviceSetting.multi_view,
      //   is_translation_enabled: this.deviceSetting.is_translation_enabled,
      //   analytics_start_time:
      //     this.currentFormattedDate + 'T' + this.analytics_start_time,
      //   analytics_end_time:
      //     this.currentFormattedDate + 'T' + this.analytics_end_time,
      //   projector_enable: this.deviceSetting.projector_enable,
      // };

      data = Object.entries(data)
        .filter(([key, val]) => this.item[key] !== val)
        .reduce((res, el) => {
          res[el[0]] = el[1];
          return res;
        }, {});

      if (this.isTaskChanged()) {
        data['Task'] = this.deviceDetails.Task;
        if (this.isTrainedTask(this.deviceDetails.Task))
          data['isInferenceRunning'] = false;
        this.isTaskUpdating = true;
      }

      return JSON.stringify(data);
    },

    isShiftBeingManaged() {
      const { is_shift_managed } = this.item;
      if (is_shift_managed) {
        return this.renderErrorToast(
          'You cannot start inference, manage shift or change task when recording shift.'
        );
      }
      return true;
    },

    async updateDevice() {
      this.isApplyingChanges = true;
      if (!this.validateRecordInference()) {
        this.isApplyingChanges = false;
        return;
      }

      if (!this.validateRecordShift()) {
        this.isApplyingChanges = false;
        return;
      }

      const requestPayload = this.setupUpdateDeviceSetting();
      console.log('requestPayload', JSON.parse(requestPayload));

      const updatedDeviceSetting = await this.SendDeviceUpdate(requestPayload);
      updatedDeviceSetting['Task'] = updatedDeviceSetting?.Task?.id;
      const deviceInfo = updatedDeviceSetting.Device;
      delete updatedDeviceSetting.Device;
      this.$emit('updateDeviceList', {
        ...deviceInfo,
        ...updatedDeviceSetting,
        type: true
      });
      this.isTaskUpdating = false;
      this.isApplyingChanges = false;
    },

    SendDeviceUpdate(requestPayload) {
      return new Promise(async resolve => {
        const [error, data] = await DeviceService.updateDeviceSettings(
          this.organization,
          this.item.id,
          requestPayload,
          false
        );
        if (error) this.renderErrorToast('Failed to update device settings');
        else
          this.toast.success(
            'We are applying your changes. This should take a minute.'
          );
        resolve(data || {});
      });
    },

    async disableDeviceManagementBeforeUnassign() {
      let data = {
        manage: false
      };
      const requestdata = JSON.stringify(data);
      return await this.SendDeviceUpdate(requestdata, false);
    },

    openUnAssignDialog() {
      this.deviceToUnassign = this.item[this.tableRowColumns[0]];
    },

    showStream() {
      this.showStreamModal = true;
      this.streamSignal = true;
      this.$emit('isModalOpen', true);
      console.log(this.deviceSetting, this.deviceSettingUrl);
      // this.streamSignalPolling = setInterval(this.sendSignalToStream, 2000);
    },
    // handleSignalTowerModal() {
    //   this.showTowerLightModal = true;
    // },
    hidePateliteTower() {
      this.showTowerLightModal = false;
    },
    handleStreamModalClose() {
      this.streamSignal = false;
      this.$emit('closeModal', false);
      this.resetStreamPolling();
    },

    async handleSaveDeviceName() {
      if (!this.isEdit) {
        this.isEdit = true;
        this.oldDisplayName = this.deviceDetails.display_name;
        return;
      }
      this.$emit('updateDeviceList', this.deviceDetails);
      const [error] = await DeviceService.updateDevice(
        this.item.Serial_number,
        this.deviceDetails,
        false
      );
      if (error) {
        this.toast.error('Unable to update the name!');
        this.$emit('updateDeviceList', {
          ...this.deviceDetails,
          display_name: this.oldDisplayName
        });
      }
      this.isEdit = false;
    },

    async handleChangeStarred() {
      const payload = {
        ...this.item,
        starred: !this.item.starred
      };
      this.$emit('updateDeviceList', {
        ...payload
      });

      const [error] = await DeviceService.updateDevice(
        this.item.Serial_number,
        payload,
        false
      );
      console.log(error);
    },

    resetPolling() {
      clearTimeout(this.polling);
      this.resetDeviceStatusPolling();
      clearInterval(this.streamSignalPolling);
    },

    resetDeviceStatusPolling() {
      clearTimeout(this.deviceStatusPolling);
    },

    resetStreamPolling() {
      clearInterval(this.streamSignalPolling);
    },

    updateDaysOfSchedule(days) {
      this.deviceDetails.day_of_week_for_recording = JSON.stringify(days);
    },
    updateTimeOfSchedule(scheduleType, start, end) {
      if (scheduleType) this.deviceDetails.is_shift_managed = true;
      if (scheduleType === 'Recording') {
        this.deviceDetails.start_recording_time = start;
        this.deviceDetails.end_recording_time = end;
      } else if (scheduleType === 'Analytics') {
        this.deviceDetails.analytics_start_time = start;
        this.deviceDetails.analytics_end_time = end;
      } else if (scheduleType === 'Trace') {
        this.deviceDetails.trace_start_time = start;
        this.deviceDetails.trace_end_time = end;
      }
    },
    handleScheduleForSelectedType() {
      this.$refs.ScheduleTask.handleScheduleForSelectedType();
      this.getDeviceSettings();
      // this.toggleScheduleTaskModal();
      this.closeScheduleTaskModal();
    },
    async switchCamera(record) {
      const payload = {
        data: { switchCamera: true },
        type: 'set_flip_camera',
        message_time: new Date().toISOString(),
        platform: 'portal',
        organization: localStorage.getItem('organization'),
        device_id: record.Serial_number
      };
      const [error, data] = await fcm.sendMessageToRabbitMQ(payload, false);
      if (error) throw 'Error while sending set telemetry request';
    }
  }
};
</script>

<style scoped>
label {
  font-size: 13px;
}

.image-icon {
  cursor: pointer;
  color: #2391ff;
}

.disabled {
  pointer-events: none;
  color: lightgray;
}

.image-icon:hover {
  color: #34495e;
  transform: scale(1.1, 1.1);
}

.display-name {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
}

.custom-control-label {
  white-space: nowrap;
  font-size: 12px;
}

td {
  padding: 0.5em;
}

.download-upload-details {
  white-space: nowrap;
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: center;
}

/* .stream-modal-body .ant-modal-body {
  padding: 0 12px !important;
} */
</style>
