<template>
  <a-radio-group
    v-model:value="selectedScheduleType"
    size="small"
    :disabled="isControlsDisabled"
    style="width: 200px;text-align: center;"
    button-style="solid"
    @change="onTypeChange"
  >
    <!-- <a-radio-button value="Recording">Recording</a-radio-button>
    <a-radio-button value="Analytics">Analytics</a-radio-button>
    <a-radio-button value="Trace">Trace</a-radio-button> -->
    <a-radio-button v-for="type in scheduleTypes" :key="type" :value="type">{{
      type
    }}</a-radio-button>
  </a-radio-group>
  <a-time-range-picker
    id="dm-time-range-input"
    :disabled="!selectedScheduleType || isControlsDisabled"
    format="HH:mm"
    class="mt-1"
    style="width: 200px"
    @change="onTimeRangeChange"
  />
  <a-select
    id="dm-schedule-task-days-select"
    v-model:value="selectedDays"
    :disabled="!selectedScheduleType || isControlsDisabled"
    class="w-100 mt-1"
    mode="multiple"
    placeholder="Select day(s)"
    @change="onDaysSelect"
    :options="daysOptions"
  />
</template>

<script>
import daysOptions from 'src/config/days-config.js';
import dateHelper from '../../../shared/Helpers/dateHelper';
import { inject, watch } from 'vue';

export default {
  name: 'ScheduleTaskColumn',
  props: ['showScheduleTaskModal', 'record', 'isControlsDisabled'],
  emits: [
    'toggleScheduleTaskModal',
    'updateDaysOfSchedule',
    'updateTimeOfSchedule'
  ],
  setup() {
    return {
      daysOptions
    };
  },
  data() {
    return {
      scheduleTypes: ['Recording', 'Analytics', 'Trace'],
      selectedScheduleType: null,
      startEndTime: [],
      selectedDays: []
    };
  },
  watch: {
    // selectedScheduleType(value) {
    //   // this.selectedDays = JSON.parse(this.record?.day_of_week_for_recording);
    //   console.log('type', value);
    //   if (value === 'Recording') {
    //     this.deviceSettingsRecording.showScheduleModal(this.record);
    //   } else if (value === 'Analytics') {
    //     this.deviceSettingsAnalytics.showScheduleModal(this.record);
    //   } else if (value === 'Trace') {
    //     this.deviceSettingsTrace.showScheduleModal(this.record);
    //   }
    // }
    // startEndTime(value){
    // }
  },
  methods: {
    onTimeRangeChange(value) {
      console.log('time:', value);
      this.startEndTime = value;
      //   if (value.length === 2) {
      //     this.startEndTime = value;
      //   }
      if (this.startEndTime) {
        let [start, end] = this.getStartEndTime();
        this.$emit(
          'updateTimeOfSchedule',
          this.selectedScheduleType,
          start,
          end
        );
        console.log(this.record, start, end);
      }
    },
    onTypeChange(event) {
      const { value } = event.target;
      this.selectedScheduleType = value;
      console.log('type:', value);
    },
    getStartEndTime() {
      const [start, end] = this.startEndTime;
      if (!start.$d && !end.$d) {
        this.toast.info('Schedule time is not properly selected!');
        //   isScheduling.value = false;
        return;
      }
      const startDate = dateHelper.getFormattedDate(start.$d);
      const endDate = dateHelper.getFormattedDate(end.$d);
      const [startTime] = new Date(start.$d).toTimeString().split(' ');
      const [endTime] = new Date(end.$d).toTimeString().split(' ');
      return [startDate + 'T' + startTime, endDate + 'T' + endTime];
    },
    onDaysSelect(days) {
      console.log('days', days);
      console.log(this.record);
      this.$emit('updateDaysOfSchedule', days);
    }
    // getSchedulePayload() {
    //   const [start, end] = this.getStartEndTime(this.startEndTime);
    //   const taskId = this.record.Task;
    //   const scheduleType = this.selectedScheduleType;

    //   const schedule = {
    //     Task: taskId,
    //     is_shift_managed: true,
    //     day_of_week_for_recording: JSON.stringify(this.selectedDays),
    //     shift_start_time: null,
    //     shift_end_time: null
    //   };

    //   if (scheduleType === 'Recording') {
    //     schedule.start_recording_time = start;
    //     schedule.end_recording_time = end;
    //   } else if (scheduleType === 'Analytics') {
    //     schedule.analytics_start_time = start;
    //     schedule.analytics_end_time = end;
    //   } else if (scheduleType === 'Trace') {
    //     schedule.trace_start_time = start;
    //     schedule.trace_end_time = end;
    //   }

    //   return schedule;
    // },
    // async handleSchedule() {
    //   console.log('time', this.startEndTime);
    //   const { Serial_number } = this.record;
    //   const payload = this.getSchedulePayload();
    //   console.log(payload);
    //   const { id } = this.record;

    //   if (!canStartRecording(Serial_number)) return;

    //   await updateDeviceSettings(id, payload);
    //   showSchedule.value = false;
    //   isScheduling.value = false;
    // }
  }
};
</script>
