import { mapActions, mapGetters } from 'vuex';
import { isValidJSON } from 'src/utils/jsonHelper';

export default {
  data() {
    return {
      fcmMessagesDetails: {},
    };
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  methods: {
    ...mapActions([]),
    getMessageData(payload) {
      const temp = { ...this.fcmMessagesDetails };
      const { chunk_id, data, type, device_id, total_chunks } = payload.data;

      if (isValidJSON(data)) return JSON.parse(data);

      const key = `${device_id}-${type}`;
      const messageIndex = chunk_id.split('-')[0] - 1;
      const parsedData = JSON.parse(data)
        .replace('b', '')
        .replaceAll("'", '');
      if (temp[key]) {
        temp[key][messageIndex] = parsedData;

        if (temp[key].every((e) => !!e)) {
          const messageData = JSON.parse(atob(temp[key].join('')));
          delete temp[key];
          this.fcmMessagesDetails = temp;
          return messageData;
        }
      } else {
        temp[key] = new Array(Number(total_chunks)).fill(null);
        temp[key][messageIndex] = parsedData;
      }
      this.fcmMessagesDetails = temp;
      return;
    },
  },
};
