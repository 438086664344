<template>
  <div>
    <a-modal
      v-model:visible="showNotificationPermissionModal"
      title="Notification Permissions"
      centered
      :closable="false"
      :footer="null"
      width="50%"
    >
      <p style="font-size: 14px; font-weight: bold;">{{ modalText }}</p>
      <p style="font-size: 16px; font-weight: bold;">Tutorial to turn on notification on your browser:</p>
      <a style="font-size: 15px; font-weight: bold;" href="javascript:void(0)" @click="browser='chrome'"><chrome-outlined/> &nbsp;Chrome</a>
      <br>
      <a style="font-size: 15px; font-weight: bold;" href="javascript:void(0)" @click="browser='edge'"><ie-outlined /> &nbsp;Edge</a>
      <br>
      <br>
      <span v-show="browser">
        <img :src="browser=='chrome' ? chromeGifPath : edgeGifPath" style="width: 100%; height: auto;">
    </span>
    </a-modal>
  </div>
</template>
<script>
import { onMounted, onUnmounted } from 'vue';
import { setupFCM } from 'src/utils/fcm';
import { handleNotificationPermissions } from 'src/mixins/deviceNotification';
import { ChromeOutlined,IeOutlined } from '@ant-design/icons-vue';
import edgeGif from '@/assets/gifs/edgeGif.gif'; 
import chromeGif from '@/assets/gifs/chromeGif.gif'; 

export default {
  components:{
    ChromeOutlined,
    IeOutlined
  },
  inject: ['toast'],
  data() {
    return {
      modalText: '',
      showNotificationPermissionModal: false,
      organization : 'retrocausal',
      chromeNotification: '',
      edgeNotification: '',
      browser: '',
      edgeGifPath: edgeGif,
      chromeGifPath: chromeGif

    };
  },
  async created() {
    this.checkDeviceNotificationSetting();
  },
  setup() {
    let intervalId;
    const startPolling = () => {
      handleNotificationPermissions(); // Initial fetch
      intervalId = setInterval(handleNotificationPermissions, 5000); // Fetch every 5 seconds (adjust this as needed)
    };

    const stopPolling = () => {
      clearInterval(intervalId);
    };

    // Start polling when the component is mounted
    onMounted(startPolling);

    // Stop polling when the component is unmounted to prevent memory leaks
    onUnmounted(stopPolling);
  },
  methods:{
    checkDeviceNotificationSetting() {
      const permission = Notification.permission
      if (localStorage.getItem('fcmToken')) {
        localStorage.removeItem('fcmToken');
      } 
      if (permission === 'denied' || permission === 'default') {
        this.modalText =
          'You have blocked notifications. For the devices status to appear, please enable them in your browser settings for our website.';
        this.showNotificationPermissionModal = true;
      }
      else if (permission === 'granted')
      {
        setupFCM();
      }
      
    },
  }
}
</script>
